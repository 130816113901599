
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { EventList } from '@/types/devices'

@Component
export default class MonitoringConfig extends Vue {
  @Prop() readonly deviceId!: string
  @Ref('form') readonly form!: ElForm

  loading = false
  objectId = ''

  eventList: Array<EventList> =[]

  created () {
    this.objectId = this.deviceId
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios
      .get(this.$apis.devices.getCameraData, {
        deviceId: this.deviceId
      })
      .then((res) => {
        if (res && res.configList) {
          this.eventList = res.configList
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
