
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DicInfo, DicList } from '../../types/common'
import { TrashConfig } from '../../types/devices'

@Component
export default class Config extends Vue {
  @Prop() readonly deviceId!: string;
  loading = false

  deviceInfo: TrashConfig = {
    deviceId: '',
    trashcan1Type: '',
    trashcan2Type: '',
    trashcan3Type: '',
    trashcan4Type: '',
    trashcan5Type: '',
    trashcan6Type: ''
  }

  dicList: DicInfo[] = []

  created () {
    this.loadData()
    this.loadTrashcanType()
  }

  trashcanName (value: string) {
    const index = this.dicList.findIndex(item => item.dicCode === value)
    return index >= 0 ? this.dicList[index].dicValue : '--'
  }

  loadData () {
    this.$axios.get(this.$apis.devices.selectTrashcanConfigById, {
      deviceId: this.deviceId
    }).then(res => {
      res && (this.deviceInfo = res)
    })
  }

  loadTrashcanType () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'trashcanType' }).then(res => {
      this.dicList = res.trashcanType
    })
  }
}
