import { render, staticRenderFns } from "./TrashConfig.vue?vue&type=template&id=2ee4edc3&scoped=true"
import script from "./TrashConfig.vue?vue&type=script&lang=ts"
export * from "./TrashConfig.vue?vue&type=script&lang=ts"
import style0 from "./TrashConfig.vue?vue&type=style&index=0&id=2ee4edc3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee4edc3",
  null
  
)

export default component.exports