
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Species } from '../../types/devices'
import { integerWithin, validNumberOne, validNumberThree, validNumberFour } from '@/utils/validate'

@Component
export default class SoilHumidityConfig extends Vue {
  @Prop() readonly deviceId!: string;
  @Ref('form') readonly form!: ElForm;

  loading = false
  projectId = ''
  // 氮磷钾ORP输入框列表
  monitorList: Array<string> = []
  deviceForm = {
    areaId: '',
    objectId: '',
    maintType: '',
    depth: '',
    // excessiveDrought: '',
    drought: '',
    saturated: '',
    frozen: '',
    overheated: '',
    // 氮磷钾ORP
    nitrogenUpperLimit: '',
    nitrogenLowerLimit: '',
    phosphorusUpperLimit: '',
    phosphorusLowerLimit: '',
    potassiumUpperLimit: '',
    potassiumLowerLimit: '',
    orpLowerLimit: '',
    orpUpperLimit: ''
  }

  // 物种列表
  species: Species[] = []

  rules = {
    maintType: [{ required: true, message: '请选择养护物种', trigger: 'change' }], // 养护物种编号
    depth: [{ required: true, message: '请输入距离地面深度', trigger: 'blur' }], // 距离地面深度/CM
    drought: [
      { required: true, message: '请输入土壤湿度干旱阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度干旱阈值/%
    saturated: [
      { required: true, message: '请输入土壤湿度饱和阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度饱和阈值/%
    frozen: [
      { required: true, message: '请输入土壤温度冰冻阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度冰冻阈值/℃
    overheated: [
      { required: true, message: '请输入土壤温度过热阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度过热阈值/℃
    nitrogenLowerLimit: [
      { required: true, message: '请输入氮含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    nitrogenUpperLimit: [
      { required: true, message: '请输入氮含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusLowerLimit: [
      { required: true, message: '请输入磷含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusUpperLimit: [
      { required: true, message: '请输入磷含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumLowerLimit: [
      { required: true, message: '请输入钾含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumUpperLimit: [
      { required: true, message: '请输入钾含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    orpLowerLimit: [
      { required: true, message: '请输入ORP值偏低阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ],
    orpUpperLimit: [
      { required: true, message: '请输入ORP值偏高阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    this.deviceForm.objectId = this.deviceId
    this.loadData()
    this.loadSpecies()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.getSoilConfig, {
      deviceId: this.deviceId
    }).then(res => {
      res && (this.deviceForm = res)
      this.getMonitorList(res)
    })
  }

  getMonitorList (res: any) {
    const arr = ['氮', '磷', '钾', 'ORP']
    if (res.typeStr) {
      arr.forEach((item, index) => {
        this.monitorList[index] = res.typeStr.search(item) !== -1 ? '0' : '1'
      })
    }
  }

  loadSpecies () {
    this.$axios.get<Species[]>(this.$apis.devices.getSpecies).then(res => {
      this.species = res
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        this.loading = true
        this.$axios.post(this.$apis.devices.updateSoilConfig, this.deviceForm).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
        }).finally(() => {
          this.loading = false
        })
      }
    })
  }
}
