import { render, staticRenderFns } from "./DumpConfig.vue?vue&type=template&id=48ac8a68&scoped=true"
import script from "./DumpConfig.vue?vue&type=script&lang=ts"
export * from "./DumpConfig.vue?vue&type=script&lang=ts"
import style0 from "./DumpConfig.vue?vue&type=style&index=0&id=48ac8a68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ac8a68",
  null
  
)

export default component.exports