
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component
export default class WaterConfig extends Vue {
  private submitShow = false
  private info: { alarmWaterLevel: string } = {
    alarmWaterLevel: ''
  }

  private rulesLess160 = (rule: object, value: string, callback: Function) => {
    if (value && +value >= 160) {
      return callback(new Error('请填入范围（0-160)的数'))
    } else {
      return callback()
    }
  }

  private rules = {
    alarmWaterLevel: [
      { required: true, trigger: 'change', message: '请输入告警阈值（cm）' },
      { trigger: 'change', validator: pInteger },
      { trigger: 'change', validator: this.rulesLess160 }
    ]
  }

  get deviceId () {
    return this.$route.params.deviceId as string || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.devices.selectDraftConfigById, {
      id: this.deviceId
    }).then(res => {
      this.info.alarmWaterLevel = res.alarmWaterLevel || ''
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.devices.insertDraftConfig, {
          deviceId: this.deviceId,
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
